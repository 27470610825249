<template>
  <div v-if="navigation">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('navigation.create')"
      :title="$t('navigation.create')"
      @submitPressed="submitPressed"
    />
    <navigation-form
      :navigation-obj="navigation"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import NavigationForm from "./components/NavigationForm.vue";

export default {
  components: {
    TitleBar,
    NavigationForm,
  },
  data() {
    return {
      action: null,
      navigation: {
          name: '',
          placement: '',
          menu: []
      },
    };
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
